import React from 'react';
import { Button } from 'primereact/button';

import {useGetDevicesQuery} from "../../services/appliancemonitor";

function Devicelist() {

    const { data: devicesData , isLoading: devicesLoading} = useGetDevicesQuery();

    if(devicesLoading) {
        return null;
    }

    const rows = [];
    if (devicesData !== undefined) {
        devicesData.data.forEach((device) => {
            rows.push(
                <div key={device.id} className="col-12 lg:col-6 xl:col-3">
                    <div className="surface-card shadow-2 p-3 border-1 border-50 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <div className="text-900 font-medium text-xl">{device.name}</div>
                            </div>
                            <div
                                className="flex align-items-center justify-content-center bg-gray-100 border-round"
                                style={{width: '2.5rem', height: '2.5rem'}}>
                                <i className="pi text-gray-500 pi-cog text-xl"></i>
                            </div>
                        </div>
                        <span className="text-green-500 font-medium">Running </span>
                        <span className="text-500">for 24 minutes</span>
                    </div>
                </div>
            )
            ;
        });
    }

    return (
        <React.Fragment>

            <div className="flex flex-column flex-auto">

                <div className="p-5">
                    <div className="grid">

                        {/* List of devices */}
                        {rows}

                        {/* Timeline section */}
                        <div className="col-12">
                            <div className="border-round p-4">
                                <div className="text-xl text-900 font-medium mb-5">System timeline</div>
                                <div className="mb-5 flex">
                                    <div className="flex flex-column align-items-center" style={{width: '2rem'}}>
                                    <span
                                        className="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle p-2">
                                        <i className="pi pi-compass text-xl"></i>
                                    </span>
                                        <div className="h-full bg-blue-500"
                                             style={{width: '2px', minheight: '4rem'}}></div>
                                    </div>
                                    <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                                        <div className="mb-3">
                                            <span className="text-900 font-medium inline-block mr-3">Jacob Jones</span>
                                            <span className="text-500 text-sm">1 minute ago</span>
                                        </div>
                                        <div className="line-height-3 text-700 mb-3">
                                            Eu tincidunt tortor aliquam nulla facilisi cras fermentum.
                                            Sollicitudin nibh sit amet commodo nulla. Mauris in aliquam sem fringilla ut
                                            morbi.
                                        </div>
                                        <div className="text-500 flex align-items-center gap-4">
                                            <div className="flex align-items-center gap-1">
                                                <i className="pi pi-heart"></i>
                                                <span>0</span>
                                            </div>
                                            <div className="flex align-items-center gap-1">
                                                <i className="pi pi-comment"></i>
                                                <span>1</span>
                                            </div>
                                            <div className="flex align-items-center gap-1">
                                                <i className="pi pi-eye"></i>
                                                <span>24</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-5 flex">
                                    <div className="flex flex-column align-items-center" style={{width: '2rem'}}>
                                    <span
                                        className="bg-orange-500 text-0 flex align-items-center justify-content-center border-circle p-2">
                                        <i className="pi pi-list text-xl"></i>
                                    </span>
                                        <div className="h-full bg-orange-500"
                                             style={{width: '2px', minheight: '4rem'}}></div>
                                    </div>
                                    <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                                        <div className="mb-3">
                                            <span className="text-900 font-medium inline-block mr-3">Theresa Webb</span>
                                            <span className="text-500 text-sm">2 hours ago</span>
                                        </div>
                                        <div className="line-height-3 text-700 mb-3">
                                            Purus sit amet volutpat consequat mauris. Pretium lectus quam id leo in
                                            vitae.
                                            Posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus
                                            semper.
                                        </div>
                                        <div className="text-500 flex align-items-center gap-4">
                                            <div className="flex align-items-center gap-1">
                                                <i className="pi pi-heart"></i>
                                                <span>26</span>
                                            </div>
                                            <div className="flex align-items-center gap-1">
                                                <i className="pi pi-comment"></i>
                                                <span>6</span>
                                            </div>
                                            <div className="flex align-items-center gap-1">
                                                <i className="pi pi-eye"></i>
                                                <span>673</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-5 flex">
                                    <div className="flex flex-column align-items-center" style={{width: '2rem'}}>
                                    <span
                                        className="bg-purple-500 text-0 flex align-items-center justify-content-center border-circle p-2">
                                        <i className="pi pi-inbox text-xl"></i>
                                    </span>
                                        <div className="h-full bg-purple-500"
                                             style={{width: '2px', minheight: '4rem'}}></div>
                                    </div>
                                    <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
                                        <div className="mb-3">
                                            <span className="text-900 font-medium inline-block mr-3">Walter Black</span>
                                            <span className="text-500 text-sm">4 hours ago</span>
                                        </div>
                                        <div className="line-height-3 text-700 mb-3">
                                            Euismod in pellentesque massa placerat duis ultricies lacus.
                                            Vitae sapien pellentesque habitant morbi tristique senectus et netus et.
                                        </div>
                                        <div className="text-500 flex align-items-center gap-4">
                                            <div className="flex align-items-center gap-1">
                                                <i className="pi pi-heart"></i>
                                                <span>62</span>
                                            </div>
                                            <div className="flex align-items-center gap-1">
                                                <i className="pi pi-comment"></i>
                                                <span>36</span>
                                            </div>
                                            <div className="flex align-items-center gap-1">
                                                <i className="pi pi-eye"></i>
                                                <span>21</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </React.Fragment>
    );

}

export default Devicelist;