import { Menubar } from 'primereact/menubar';
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

function NavBar() {

    const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

    if (isLoading) {
        return null;
    }
    const loggedOutItems = [
        {
            label: 'Appliance Monitor',
            icon: 'fab fa-cloudversify',
            url: '/#/'
        },
        {
            label: 'Login',
            command: () => loginWithRedirect(),
        }
    ];

    const loggedInItems = [
        {
            label: 'Appliance Monitor',
            icon: 'fab fa-cloudversify',
            url: '/#/'
        },
        {
            label: 'Settings',
            url: '/#/settings'
        },
        {
            label: 'Logout',
            command: () => logout(),
        }
    ];

    return (
        <div className="card">
            {
                isAuthenticated ? (
                    <Menubar model={loggedInItems} />
                ) : (
                    <Menubar model={loggedOutItems} />
                )
            }
        </div>
    );
}

export default NavBar;
