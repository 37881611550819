import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

function SettingsHome() {

    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return null;
    }

    console.log(user)

    return (
        <React.Fragment>

            {
                isAuthenticated ? (
                    <div className="grid m-auto">
                        <div className="col-9">
                            This is the settings for {user.email}
                        </div>
                    </div>
                ) : (
                    <div className="grid m-auto">
                        <div className="col-9">
                            Please login
                        </div>
                    </div>
                )
            }

        </React.Fragment>
    );
}

export default SettingsHome;