//  React
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

//  App
import './index.css';
import App from './App';

//  React router
import {HashRouter} from "react-router-dom";

//  Auth
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
            <HashRouter>
                <Auth0Provider
                    domain="appliance-monitor.us.auth0.com"
                    clientId="hvVYuNvAioWz7klDFTVxgPw2MagVMSVx"
                    authorizationParams={{
                        redirect_uri: window.location.origin,
                        audience: "https://app.appliance-monitor.com/v1",
                    }}
                    cacheLocation="localstorage"
                >
                    <App />
                </Auth0Provider>
            </HashRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
