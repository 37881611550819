import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Devicelist from "./dashboard/devicelist";

function ApplianceMonitorHome() {

    const { user, isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return null;
    }

    return (
        <React.Fragment>

            {
                isAuthenticated ? (
                    <Devicelist />
                ) : (
                    <div className="grid m-auto">
                        <div className="col-9">
                            Please login
                        </div>
                    </div>
                )
            }

        </React.Fragment>
    );

}

export default ApplianceMonitorHome;